<template lang="html">




<div class="container">

  <img src="@/assets/logonewest.svg" alt="" id="logo">

<router-link class="bn39" to="/global">
  <span className="bn39span">Global</span>
</router-link>

<router-link class="bn39" to="/ksusluge">
  <span className="bn39span">Usluge</span>
</router-link>

<router-link class="bn39" to="/login">
  <span className="bn39span">Klijenti</span>
</router-link>

</div>

</template>

<script>
import {mapActions} from 'vuex';
export default {
  name: 'Landing',
  methods:{
    ...mapActions(['setSrb']),
  },
  created(){
    this.setSrb();
  },
  beforeCreate: function() {
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      document.body.className = 'landingMobile';
    } else {
      document.body.className = 'landing';
    }
  }
}
</script>

<style lang="css" scoped>

.container{
  text-align: center;
 position: absolute;
 width: 70%;

}
h1{
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  color: white;
  text-align: center;
  border-radius: 15px;
  border-style: solid;
  border-color: black;
  width: 50%;
  background-color: #335577;
}


.bn39 {
  background-image: linear-gradient(135deg, #008aff, #86d472);
  border-radius: 6px;
  box-sizing: border-box;
  color: #ffffff;
  display: block;
  height: 3em;
  font-size: 1.4em;
  font-weight: 600;
  padding: 4px;
  position: relative;
  text-decoration: none;
  width: 7em;
  z-index: 2;
  width: 50%;
  margin-bottom: 1em;
}
@media screen and (max-width: 568px) {
  #logo{
    margin-top: 20px;
    margin-bottom: 40px;

  }
}

.bn39:hover {
  color: #fff;
}

.bn39 .bn39span {
  align-items: center;
  background: #0e0e10;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  height: 100%;
  transition: background 0.5s ease;
  width: 100%;
}

.bn39:hover .bn39span {
  background: transparent;
}

#logo{

}

</style>
